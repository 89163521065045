import { time, type TimeDate } from '@distributedlab/tools'
import { FormLabel, Stack, useTheme } from '@mui/material'
import { DateTimeValidationError, DateValidationError } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { forwardRef, useCallback, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

type Props = {
  errorMessage?: string
  onChange?: (v: string | null) => void
  hasTime?: boolean
} & Omit<DatePickerProps<TimeDate>, 'onChange'> &
  Omit<DateTimePickerProps<TimeDate>, 'onChange'>

const UiDatePicker = forwardRef<HTMLInputElement, Props>(
  ({ errorMessage, label, hasTime = false, ...rest }: Props, ref) => {
    const { palette } = useTheme()
    const fieldId = useMemo(() => `ui-date-picker-${uuidv4()}`, [])
    const [internalErrorMessage, setInternalErrorMessage] = useState<string | null>(null)

    const minDate = useMemo(() => {
      if (!rest.minDate) return undefined

      return time(rest.minDate).utc().dayjs
    }, [rest])

    const maxDate = useMemo(() => {
      if (!rest.maxDate) return undefined

      return time(rest.maxDate).utc().dayjs
    }, [rest])

    const minTime = useMemo(() => {
      if (!rest.minTime) return undefined

      return time(rest.minTime).utc().dayjs
    }, [rest])

    const maxTime = useMemo(() => {
      if (!rest.maxTime) return undefined

      return time(rest.maxTime).utc().dayjs
    }, [rest])

    const value = useMemo(() => {
      if (!rest.value) return null
      return time(rest.value).utc().dayjs
    }, [rest.value])

    const handleChange = useCallback(
      (v: TimeDate | null) => {
        if (v === null) {
          rest.onChange?.(null)
          return
        }
        rest.onChange?.(time(v).utc().format())
      },
      [rest],
    )

    const handleError = useCallback((reason: DateValidationError | DateTimeValidationError) => {
      if (!reason) {
        setInternalErrorMessage(null)
        return
      }

      switch (reason) {
        case 'invalidDate': {
          setInternalErrorMessage('Invalid date format')
          break
        }
        default: {
          setInternalErrorMessage('Invalid date')
        }
      }
    }, [])

    const pickerProps = useMemo(() => {
      const commonProps = {
        ...rest,
        inputRef: ref,
        value,
        onChange: handleChange,
        onError: handleError,
        minDate,
        maxDate,
        timezone: 'UTC',
        slotProps: {
          textField: {
            error: !!errorMessage || !!internalErrorMessage,
            helperText: errorMessage || internalErrorMessage,
          },
        },
      }

      if (hasTime) {
        return {
          ...commonProps,
          ampm: false,
          minTime,
          maxTime,
        } as DateTimePickerProps<TimeDate>
      }

      return commonProps as DatePickerProps<TimeDate>
    }, [
      rest,
      ref,
      value,
      handleChange,
      handleError,
      minDate,
      maxDate,
      errorMessage,
      internalErrorMessage,
      hasTime,
      minTime,
      maxTime,
    ])

    return (
      <Stack spacing={2}>
        {label && <FormLabel htmlFor={fieldId}>{label}</FormLabel>}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']} sx={{ p: 0, overflow: 'visible' }}>
            {hasTime ? (
              <DateTimePicker
                {...(pickerProps as DateTimePickerProps<TimeDate>)}
                sx={{
                  '.MuiIconButton-root': {
                    color: palette.text.secondary,
                    pr: 2,
                    '&:hover': {
                      color: palette.text.primary,
                    },
                  },
                  width: '100%',
                }}
              />
            ) : (
              <DatePicker
                {...(pickerProps as DatePickerProps<TimeDate>)}
                sx={{
                  '.MuiIconButton-root': {
                    color: palette.text.secondary,
                    pr: 2,
                    '&:hover': {
                      color: palette.text.primary,
                    },
                  },
                }}
              />
            )}
          </DemoContainer>
        </LocalizationProvider>
      </Stack>
    )
  },
)

export default UiDatePicker

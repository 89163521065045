import { type ChainId } from '@distributedlab/w3p'
import { providers } from 'ethers'
import { ref } from 'valtio'

import { NETWORK_NAME, networkConfigsMap } from '@/constants'
import { createStore } from '@/helpers'

type Web3Store = {
  provider: providers.JsonRpcProvider | undefined
  providerChainId: ChainId | undefined
}

export const [web3Store, useWeb3State] = createStore(
  'web3',
  {
    provider: undefined,
    providerChainId: undefined,
  } as Web3Store,
  state => ({
    get contractConnector(): providers.JsonRpcProvider | undefined {
      return state.provider
    },
  }),
  state => ({
    init: async () => {
      const networkConfig = networkConfigsMap[NETWORK_NAME]

      if (!networkConfig) {
        throw new Error('Network configuration not found')
      }

      const jsonRpcProvider = new providers.JsonRpcProvider(
        networkConfig.rpcUrl,
        networkConfig.chainId,
      )

      state.provider = ref(jsonRpcProvider)

      state.providerChainId = networkConfig.chainId
    },
  }),
  {
    persistProperties: ['providerChainId'],
  },
)

import { config } from '@config'
import { Chain, CHAIN_TYPES } from '@distributedlab/w3p'
import { utils } from 'ethers'

import { Icons } from '@/enums'

export type NetworkName = 'mainnet' | 'testnet'

export interface NetworkConfig {
  chainId: number
  name: string
  networkName: NetworkName
  rpcUrl: string
  explorerUrl: string
}

export interface Erc20Token {
  address: string
  name: string
  symbol: string
  decimals: number
  color: string
  icon: Icons
}

export const networkConfigsMap: Record<NetworkName, NetworkConfig> = {
  mainnet: {
    chainId: 8453,
    name: 'Base Mainnet',
    networkName: 'mainnet',
    rpcUrl: 'https://mainnet.base.org',
    explorerUrl: 'https://basescan.org',
  },
  testnet: {
    chainId: 8453,
    name: 'Base Mainnet',
    networkName: 'testnet',
    rpcUrl: 'https://mainnet.base.org',
    explorerUrl: 'https://basescan.org',
  },
}

export const connectorParametersMap = Object.values(networkConfigsMap).reduce(
  (acc, config) => {
    const token = {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    }

    acc[config.chainId] = {
      id: utils.hexlify(config.chainId),
      name: config.name,
      rpcUrl: config.rpcUrl,
      explorerUrl: config.explorerUrl,
      type: CHAIN_TYPES.EVM,
      token,
      icon: '',
    }
    return acc
  },
  {} as { [key: string]: Chain },
)

export const supportedErc20Map: Record<NetworkName, Erc20Token[]> = {
  testnet: [
    {
      address: '0x3271207bD3e5840b8fA10200BF5045B9da67266d',
      name: 'TT Token',
      symbol: 'TT',
      decimals: 18,
      icon: Icons.UsdcToken,
      color: '#2775CA',
    },
    {
      address: '0xB76ebC3b5946705B81f8bEEb64051D129B0a2AbF',
      name: 'USDC Token',
      symbol: 'USDC',
      decimals: 6,
      icon: Icons.UsdcToken,
      color: '#2775CA',
    },
  ],
  mainnet: [
    {
      address: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913',
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      icon: Icons.UsdcToken,
      color: '#2775CA',
    },
  ],
}

export const NETWORK_NAME: NetworkName = config.MODE === 'development' ? 'testnet' : 'mainnet'
export const supportedErc20 = supportedErc20Map[NETWORK_NAME]

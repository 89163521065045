import { PaletteMode } from '@mui/material'

import { createStore } from '@/helpers'
import { breakpoints } from '@/theme/breakpoints'

type UiStore = {
  viewportHeight: number
  viewportWidth: number
  paletteMode: PaletteMode
}

export const [uiStore, useUiState] = createStore(
  'ui',
  {
    viewportWidth: 0,
    paletteMode: 'light',
  } as UiStore,
  state => ({
    get isMobile() {
      return state.viewportWidth < breakpoints.values.sm
    },
    get isTablet() {
      return (
        state.viewportWidth >= breakpoints.values.sm && state.viewportWidth < breakpoints.values.md
      )
    },
    get isDesktop() {
      return state.viewportWidth >= breakpoints.values.md
    },
  }),
  state => ({
    setViewportWidth: (width: number) => {
      state.viewportWidth = width
    },
    setPaletteMode: (mode: PaletteMode) => {
      state.paletteMode = mode
    },
    clearUiStorage: () => {
      state.paletteMode = 'light'
      state.viewportWidth = 0
    },
  }),
)

import { CssBaseline, ThemeProvider } from '@mui/material'
import { FC, HTMLAttributes, memo, useCallback, useEffect, useMemo } from 'react'
import { RouterProvider } from 'react-router-dom'

import { ToastsManager } from '@/contexts'
import { ErrorHandler } from '@/helpers'
import { useLocalisedYupScheme, useViewportSizes } from '@/hooks'
import { authStore, useUiState, web3Store } from '@/store'
import { createTheme } from '@/theme'

import { createRouter } from './router'

const App: FC<HTMLAttributes<HTMLDivElement>> = () => {
  const { paletteMode } = useUiState()
  useViewportSizes()
  useLocalisedYupScheme()

  const theme = useMemo(() => createTheme(paletteMode), [paletteMode])

  const init = useCallback(async () => {
    try {
      await web3Store.init()
    } catch (e) {
      ErrorHandler.process(e)
    }
  }, [])

  useEffect(() => {
    authStore.verifyToken()
  }, [])

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastsManager>
        <RouterProvider router={createRouter()} />
      </ToastsManager>
    </ThemeProvider>
  )
}

export default memo(App)

export enum ApiServicePaths {
  Auth = '/integrations/global-auth-svc',
  OrderBook = '/integrations/global-market-orderbook-svc',
  Points = '/integrations/global-points-svc',
  Statistic = '/integrations/global-statistic-svc',
}

export enum ApiSortOrders {
  Asc = 'asc',
  Desc = 'desc',
}

import { setLocale } from 'yup'

type ValidationMessageParams<T = Record<string, unknown>> = T & {
  field?: string
  min?: string | Date | number
  max?: string | Date | number
}

export const useLocalisedYupScheme = () => {
  setLocale({
    mixed: {
      required: 'Please fill out this field',
      notType: ({ type }: ValidationMessageParams<{ type: string }>) =>
        type === 'number' ? 'This field must be a number' : `This field must be a ${type}`,
    },
    string: {
      min: ({ min, field = 'This field' }: ValidationMessageParams<{ min: number }>) =>
        `${field} must contain minimum ${min} symbols`,
      max: ({ max, field = 'This field' }: ValidationMessageParams<{ max: number }>) =>
        `${field} must contain maximum ${max} symbols`,
    },
    number: {
      moreThan: ({ more }: ValidationMessageParams<{ more: number }>) =>
        `This field must be greater than ${more}`,
      positive: 'This field must be positive',
      integer: 'This field must be an integer',
    },
    date: {
      min: ({ min }: ValidationMessageParams<{ min: string | Date }>) =>
        `Date cannot be earlier than ${String(min)}`,
    },
    array: {
      min: ({ min }: ValidationMessageParams<{ min: number }>) =>
        `This field must have at least ${min} options`,
    },
  })
}

import packageJson from '../package.json'

export type Config = {
  MODE: 'development' | 'production'

  APP_HOST_URL: string
  API_URL: string
  BUILD_VERSION: string

  TOKEN_EXCHANGE_CONTRACT: string
}

export const config: Config = {
  MODE: import.meta.env.VITE_MODE,
  APP_HOST_URL: import.meta.env.VITE_APP_HOST_URL,
  API_URL: import.meta.env.VITE_API_URL,
  BUILD_VERSION: packageJson.version || import.meta.env.VITE_APP_BUILD_VERSION,

  TOKEN_EXCHANGE_CONTRACT: import.meta.env.VITE_TOKEN_EXCHANGE_CONTRACT,
}
